import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Collapse, Divider, Flex, Typography } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../../../api";
import Responsive from "../../../../assets/config/Responsive";
import Colors from "../../../../assets/styles/Colors";
import Accordion from "../../../../components/Accordion";
import Loader from "../../../../components/Loader";
import { RootState } from "../../../../store";
import { AuthState } from "../../../../store/authSlice";
const { Text } = Typography;

const AdminBusinessModuleDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  const { itemId: moduleIdStr = "0" } = useParams();
  const moduleId = parseInt(moduleIdStr);
  const isFraniAdmin =
    (auth.user?.companyUsers[0]?.role || auth.user?.userType) === "Frani-Admin";

  const {
    data: corporateModuleDetail,
    isLoading: corporateModuleDetailsLoading,
  } = api.useGetBusinessModuleByIdQuery(moduleId);

  const corporateModuleDetails = corporateModuleDetail
    ? corporateModuleDetail[0]
    : undefined;

  return corporateModuleDetailsLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {corporateModuleDetails?.firstname && corporateModuleDetails?.lastname
            ? corporateModuleDetails?.firstname +
              " " +
              corporateModuleDetails?.lastname
            : " "}
        </Title>
      </Flex>

      <Divider />

      {isFraniAdmin && (
        <>
          <Collapse
            style={{ width: "100%" }}
            items={[
              {
                key: "1",
                label: t("companyInformation"),
                children: (
                  <Flex vertical gap={10}>
                    <Flex justify="space-between">
                      <Text>{t("companyName")}</Text>
                      <Text>
                        {corporateModuleDetails?.companyName ??
                          corporateModuleDetails?.companyName}
                      </Text>
                    </Flex>

                    <Flex justify="space-between">
                      <Text>{t("companyID")}</Text>
                      <Text>
                        {corporateModuleDetails?.idNumber ??
                          corporateModuleDetails?.idNumber}
                      </Text>
                    </Flex>
                  </Flex>
                ),
              },
            ]}
          />
        </>
      )}

      {isFraniAdmin && (
        <>
          {/* company information accordion */}
          <Accordion title={t("companyInformation")} openAccordion={true}>
            <InfoTextBox>
              <InfoTextLeftSide>{t("companyID")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.idNumber ??
                  corporateModuleDetails?.idNumber}
              </InfoTextRightSide>
            </InfoTextBox>
            <InfoTextBox>
              <InfoTextLeftSide>{t("activityField")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.activityField ??
                  corporateModuleDetails?.activityField}
              </InfoTextRightSide>
            </InfoTextBox>
          </Accordion>
          {/* company information accordion */}

          {/* health insurance accordion */}
          {corporateModuleDetails.type === "health" && (
            <Accordion title={t("healthInsurance")} openAccordion={false}>
              <InfoTextBox>
                <InfoTextLeftSide>{t("employeeNumber")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.employeesCount ??
                    corporateModuleDetails?.otherInformation.employeesCount}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("insuredEmployees")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .insuredEmployeesCount ??
                    corporateModuleDetails?.otherInformation
                      .insuredEmployeesCount}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("managementNumber")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .topManagementCount ??
                    corporateModuleDetails?.otherInformation.topManagementCount}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("employeeAverageAge")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.averageAge ??
                    corporateModuleDetails?.otherInformation.averageAge}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("genderBalance")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.genderFemale &&
                  corporateModuleDetails?.otherInformation.genderMale
                    ? `${t("female")} : ${
                        corporateModuleDetails?.otherInformation.genderFemale
                      }  ${t("male")}: ${
                        corporateModuleDetails?.otherInformation.genderMale
                      }`
                    : ""}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("minPriceInsurance")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .minInsurancePackage ??
                    corporateModuleDetails?.otherInformation
                      .minInsurancePackage}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("insurancePayer")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.insurancePayer ??
                    corporateModuleDetails?.otherInformation.insurancePayer}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("insuredRecently")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .usedInsuranceLastYear
                    ? corporateModuleDetails?.otherInformation
                        .usedInsuranceLastYear === true
                      ? `${t("yes")}`
                      : `${t("no")}`
                    : ""}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("insuredCompanyName")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .insuranceCompanyName ??
                    corporateModuleDetails?.otherInformation
                      .insuranceCompanyName}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("contractRenewalDate")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .contractRenewalDate ??
                    corporateModuleDetails?.contractRenewalDate}
                </InfoTextRightSide>
              </InfoTextBox>
            </Accordion>
          )}

          {/* health insurance accordion */}

          {/* car insurance accordion */}
          {corporateModuleDetails.type === "car" && (
            <Accordion title={t("carInsurance")} openAccordion={false}>
              <InfoTextBox>
                <InfoTextLeftSide>{t("carBrand")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.carBrand ??
                    corporateModuleDetails?.otherInformation.carBrand}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("companyCarModel")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.carModel ??
                    corporateModuleDetails?.otherInformation.carModel}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("manufactoreYear")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.manufactureYear ??
                    corporateModuleDetails?.otherInformation.manufactureYear}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("purpose")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.purpose ??
                    corporateModuleDetails?.otherInformation.purpose}
                </InfoTextRightSide>
              </InfoTextBox>
            </Accordion>
          )}
          {/* car insurance accordion */}

          {/* property insurance accordion */}
          {corporateModuleDetails.type === "property" && (
            <Accordion title={t("propertyInsurance")} openAccordion={false}>
              <InfoTextBox>
                <InfoTextLeftSide>{t("cadastralCode")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.cadastralCode ??
                    corporateModuleDetails?.otherInformation.cadastralCode}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("purpose")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.purpose ??
                    corporateModuleDetails?.otherInformation.purpose}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("propertyType")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.propertyType ??
                    corporateModuleDetails?.otherInformation.propertyType}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("propertyContents")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation
                    .insurePropertyContents
                    ? corporateModuleDetails?.otherInformation
                        .insurePropertyContents === true
                      ? `${t("yes")}`
                      : `${t("no")}`
                    : ""}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("propertyAddress")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.address ??
                    corporateModuleDetails?.otherInformation.address}
                </InfoTextRightSide>
              </InfoTextBox>
            </Accordion>
          )}

          {/* property insurance accordion */}

          {/* market value accordion */}
          {(corporateModuleDetails.type === "property" ||
            corporateModuleDetails.type === "car") && (
            <Accordion title={t("marketValue")} openAccordion={false}>
              <InfoTextBox>
                <InfoTextLeftSide>{t("marketValue")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.marketValue ??
                    corporateModuleDetails?.otherInformation.marketValue}
                </InfoTextRightSide>
              </InfoTextBox>
              <InfoTextBox>
                <InfoTextLeftSide>{t("currency")}</InfoTextLeftSide>
                <InfoTextRightSide>
                  {corporateModuleDetails?.otherInformation.currency ??
                    corporateModuleDetails?.otherInformation.currency}
                </InfoTextRightSide>
              </InfoTextBox>
            </Accordion>
          )}
          {/* market value accordion */}

          {/* other  information accordion*/}
          <Accordion title={t("otherInformation")} openAccordion={false}>
            <InfoTextBox>
              <InfoTextLeftSide>{t("insuranceProduct")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.otherInformation.insuranceProduct ??
                  corporateModuleDetails?.otherInformation.insuranceProduct}
              </InfoTextRightSide>
            </InfoTextBox>
            <InfoTextBox>
              <InfoTextLeftSide>{t("comment")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.comment ??
                  corporateModuleDetails?.comment}
              </InfoTextRightSide>
            </InfoTextBox>
          </Accordion>
          {/* other  information accordion*/}

          {/* contact information accordion */}
          <Accordion title={t("ContactInformation")} openAccordion={false}>
            <InfoTextBox>
              <InfoTextLeftSide>
                {t("firstName") + "/" + t("lastName")}
              </InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.firstname &&
                corporateModuleDetails?.lastname
                  ? corporateModuleDetails?.firstname +
                    " " +
                    corporateModuleDetails?.lastname
                  : " "}
              </InfoTextRightSide>
            </InfoTextBox>
            <InfoTextBox>
              <InfoTextLeftSide>{t("position")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.position ??
                  corporateModuleDetails?.position}
              </InfoTextRightSide>
            </InfoTextBox>
            <InfoTextBox>
              <InfoTextLeftSide>{t("email")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.email ?? corporateModuleDetails?.email}
              </InfoTextRightSide>
            </InfoTextBox>
            <InfoTextBox>
              <InfoTextLeftSide>{t("mobile")}</InfoTextLeftSide>
              <InfoTextRightSide>
                {corporateModuleDetails?.mobileNumber ??
                  corporateModuleDetails?.mobileNumber}
              </InfoTextRightSide>
            </InfoTextBox>
          </Accordion>
          {/* contact information accordion */}
        </>
      )}
    </>
  );
};

export default AdminBusinessModuleDetails;

const InfoTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  /*  */
  &:last-child {
    margin-bottom: 0px;
  }
  /*  */
  ${Responsive.mobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const InfoTextLeftSide = styled.div`
  color: ${Colors.primaryGrey};
  font-size: 14px;
`;

const InfoTextRightSide = styled.div`
  color: ${Colors.primaryBlack};
  font-size: 14px;
`;
