import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Space, Typography } from "antd";
import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../../api";
import Loader from "../../../../components/Loader";
import UploadFile from "../../../../components/UploadFile";
import {
  EnabledLanguage,
  WelcomerListItems,
  WelcomerPagesWording,
} from "../../../../domain/types";
import { useAppSelector } from "../../../../store";
import { AppState } from "../../../../store/appSlice";
import AdminLangSwicher from "../../../admin_components/AdminLangSwicher";

const { Text } = Typography;

type WelcomerPagesWordingTranslation = {
  title: string;
  body: string;
  excerpt: string;
  secondTitle: string;
  thirdTitle: string;
  secondExcerpt: string;
  fourthTitle: string;
  thirdExcerpt: string;
};

type WelcomerPageTranslation = {
  stepName: string;
  title: string;
};

const AdminWelcomerManagePagesEdit = () => {
  const { id: idStr = "0" } = useParams();
  const itemID = parseInt(idStr);
  const { slug } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { field_Language } = useAppSelector(
    (data: { app: AppState }) => data.app
  );
  // updated item
  const [updatedPageTextsState, setUpdatedPageTextsState] = useState<
    Partial<WelcomerListItems>
  >({});

  // updated main info
  const [updatedPageTextArry, setUpdatedPageTextArry] = useState<
    Partial<WelcomerPagesWording>
  >({});

  const {
    data: existingWelcomerPageItem,
    isLoading: isExistingWelcomerPageItemLoading,
    refetch: RefetchExistingWelcomerPage,
  } = api.useGetWelcomerFlowPageItemTextsByListItemIdQuery(
    { itemID: itemID as number, slug: slug as string },
    {
      skip: !itemID && !slug && true,
    }
  );

  // get welcomer flow
  const {
    data: pageTextArray,
    isLoading: isPageTextArrayLoading,
    refetch: RefetchPageTextArry,
  } = api.useGetWelcomerFlowPageTextsBySlugQuery(slug as string, {
    skip: !slug && true,
  });

  // add welcomer page texts
  const setWelcomerFlowTexts = (field: keyof WelcomerListItems, value: any) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      [field]: value,
    });
  };

  // add welcomer translations field
  const setAddWelcomerPagesTranslatedField = (
    field: keyof WelcomerPagesWordingTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextsState({
      ...updatedPageTextsState,
      translations: {
        ...updatedPageTextsState.translations,
        [language]: {
          ...updatedPageTextsState?.translations?.[language],
          [field]: value,
        },
      },
    });
  };

  // add tpl main translation fields
  const setAddWelcomerMainAddonTranslatedField = (
    field: keyof WelcomerPageTranslation,
    language: EnabledLanguage,
    value: any
  ) => {
    setUpdatedPageTextArry({
      ...updatedPageTextArry,
      translations: {
        ...updatedPageTextArry.translations,
        [language]: {
          ...updatedPageTextArry?.translations?.[language],
          [field]: value || " ",
        },
      },
    });
  };

  const [updatePageTextsByListItemId, { isLoading: isUpdatePageLoading }] =
    api.useUpdateWelcomerFlowPageItemTextsByListItemIdMutation();

  const [updateTplFlow, { isLoading: isUpdateTplFlowLoading }] =
    api.useUpdateTplFlowMutation();

  const UpdateWelcomerPageTexts = async () => {
    try {
      await updatePageTextsByListItemId(updatedPageTextsState).unwrap();
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      RefetchExistingWelcomerPage();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  const UpdateMainTexts = async () => {
    try {
      await updateTplFlow({
        item: updatedPageTextArry,
        slug: slug as string,
      }).unwrap();
      toast.success(`${t("text")} ${t("updatedSuccessfully")}`);
      RefetchPageTextArry();
    } catch (error) {
      if (error?.data?.errors?.constructor === Array) {
        const firstError = error.data.errors[0];
        const errorMessage = firstError.message;
        toast.error(errorMessage);
      } else {
        toast.error(`${t("errorOccurred")}`);
      }
    }
  };

  useEffect(() => {
    if (existingWelcomerPageItem) {
      setUpdatedPageTextsState(existingWelcomerPageItem);
    }
  }, [existingWelcomerPageItem]);

  useEffect(() => {
    if (pageTextArray) {
      setUpdatedPageTextArry(pageTextArray);
    }
  }, [pageTextArray]);

  const MainLoading =
    isExistingWelcomerPageItemLoading ||
    isUpdatePageLoading ||
    isPageTextArrayLoading ||
    isUpdateTplFlowLoading;

  return MainLoading ? (
    <Loader />
  ) : (
    <>
      <Flex justify="space-between" align="center">
        <Button
          size="large"
          type="link"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          {t("back")}
        </Button>

        <Title level={3}>
          {updatedPageTextsState?.translations?.[field_Language]?.title}
        </Title>

        <AdminLangSwicher />
      </Flex>

      <Divider />

      <Flex vertical gap={20}>
        {updatedPageTextArry?.translations?.[field_Language]?.title &&
          itemID === 0 && (
            <Space direction="vertical">
              <Text>{t("pageTitle")}</Text>
              <TextArea
                size="large"
                placeholder={t("pageTitle")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextArry?.translations?.[field_Language]?.title ||
                  ""
                }
                onChange={(event) =>
                  setAddWelcomerMainAddonTranslatedField(
                    "title",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>
          )}

        {updatedPageTextArry?.translations?.[field_Language]?.stepName &&
          itemID === 0 && (
            <Space direction="vertical">
              <Text>{t("stepName")}</Text>
              <TextArea
                size="large"
                placeholder={t("stepName")}
                autoSize={{ minRows: 1 }}
                value={
                  updatedPageTextArry?.translations?.[field_Language]
                    ?.stepName || ""
                }
                onChange={(event) =>
                  setAddWelcomerMainAddonTranslatedField(
                    "stepName",
                    field_Language,
                    event?.target?.value
                  )
                }
              />
            </Space>
          )}

        {itemID !== 0 && (
          <Space direction="vertical">
            <Text>{t("title")}</Text>
            <TextArea
              size="large"
              placeholder={t("title")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]?.title ||
                ""
              }
              onChange={(event) =>
                setAddWelcomerPagesTranslatedField(
                  "title",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {itemID !== 0 && (
          <Space direction="vertical">
            <Text>{t("body")}</Text>
            <TextArea
              size="large"
              placeholder={t("body")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]?.body ||
                ""
              }
              onChange={(event) =>
                setAddWelcomerPagesTranslatedField(
                  "body",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {itemID !== 0 && (
          <Space direction="vertical">
            <Text>{t("bodyTitle")}</Text>
            <TextArea
              size="large"
              placeholder={t("bodyTitle")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.bodyTitle || ""
              }
              onChange={(event) =>
                setAddWelcomerPagesTranslatedField(
                  "bodyTitle",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {itemID !== 0 && (
          <Space direction="vertical">
            <Text>{t("stepName")}</Text>
            <TextArea
              size="large"
              placeholder={t("stepName")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.stepName || ""
              }
              onChange={(event) =>
                setAddWelcomerPagesTranslatedField(
                  "stepName",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {itemID !== 0 && (
          <Space direction="vertical">
            <Text>{t("bodyDescription")}</Text>
            <TextArea
              size="large"
              placeholder={t("bodyDescription")}
              autoSize={{ minRows: 1 }}
              value={
                updatedPageTextsState?.translations?.[field_Language]
                  ?.bodyDescription || ""
              }
              onChange={(event) =>
                setAddWelcomerPagesTranslatedField(
                  "bodyDescription",
                  field_Language,
                  event?.target?.value
                )
              }
            />
          </Space>
        )}

        {itemID !== 0 && (
          <UploadFile
            label={t("uploadImage")}
            onChange={(props) => setWelcomerFlowTexts("image", props.url)}
            defaultFileName={updatedPageTextsState?.image || ""}
          />
        )}

        <Flex justify="center">
          {itemID !== 0 ? (
            <Button
              style={{ width: "80%" }}
              size="large"
              type="primary"
              onClick={UpdateWelcomerPageTexts}
            >
              {t("update")}
            </Button>
          ) : (
            <Button
              style={{ width: "80%" }}
              size="large"
              type="primary"
              onClick={UpdateMainTexts}
            >
              {t("update")}
            </Button>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default AdminWelcomerManagePagesEdit;
